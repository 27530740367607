import { get } from 'lodash-es';
import Vue from 'vue';

export default function (text, data) {
  if (!window.$ROBODOCXS) {
    return text;
  }
  let message = get(window.$ROBODOCXS.messages, text, text);

  if (Vue.config.devtools && text === message) {
    if (!window.$ROBODOCXS.translations) {
      window.$ROBODOCXS.translations = [];
    }
    if (!window.$ROBODOCXS.translations.includes(text)) {
      window.$ROBODOCXS.translations.push(text);
      console.warn(`Missing translation: ${text}`);
    }
  }

  if (data) {
    Object.keys(data).map((key) => {
      const exp = new RegExp(`:(${key})\\b`);
      message = message.replace(exp, data[key]);
    });

    if (message.match(/:/)) {
      Object.keys(data).map((key) => {
        const exp = new RegExp(`:(${key})\\b`);
        message = message.replace(exp, data[key]);
      });
    }
  }

  if (message.match(/:/)) {
    console.log({ message, data });
  }

  return message;
}
